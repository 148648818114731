import React from "react";
import { Link } from "gatsby";
import itinerary from "~/images/itineraries.svg";
import guide from "~/images/guide.svg";
import newsl from "~/images/newsl.png";
import minded from "~/images/like-minded.svg";
import bicycle from "~/images/bicycle.svg";
import twitter from "~/images/twitter.svg";
import facebook from "~/images/footer-facebook.svg";
import linkedin from "~/images/footer-linkedin.svg";
import instagram from "~/images/footer-instagram.svg";
import youtube from "~/images/footer-youtube.svg";
import { ChevronUpIcon } from "@heroicons/react/outline";
import Location from "~/components/location";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
	return (
		<footer className="">
			<hr></hr>
			<section className="">
				{/* <div className="flex flex-col md:flex-row bg-secondary w-auto h-auto p-2 rounded-lg my-20">
					<div className="relative md:w-1/2 pb-newsletter mb-8 ">
						<iframe
							frameborder="0"
							allowfullscreen
							className="absolute top-0 w-full h-full"
							src="https://app.mailjet.com/widget/iframe/2P7M/KNJ"
						></iframe>
					</div>
					<div className="md:w-1/2  lg:ml-28 lg:mr-20">
						<StaticImage
							className="rounded-lg shadow-lg transform"
							src="../images/newsl.png"
							alt="private adventures"
							layout="fullWidth"
							aspectRatio={0.77}
						/>
					</div>
					
				</div> */}
				{/* <div className="relative h-0 overflow-hidden max-w-full w-full pb-newsletter mt-14">
					<iframe
						src="https://cdn.forms-content.sg-form.com/86501061-edc8-11eb-8291-ae3978827eda"
						frameborder="0"
						allowfullscreen
						className="absolute top-0 left-0 w-full h-full"
					></iframe>
				</div> */}
				<div className="container px-5 py-24 mx-auto">
					<div className="text-center mb-20">
						<h1 className="mb-4">What to Expect</h1>
						<p className="font-light leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
							We put customer expectations at the centre of the way we work, building
							them into our products and service, and giving customers confidence in
							Roots & Routes.
						</p>
						<div className="flex mt-6 justify-center">
							<div className="w-16 h-1 rounded-full bg-primary inline-flex"></div>
						</div>
					</div>
					<div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
						<div className="p-4 md:w-1/2 lg:w-1/4 flex flex-col text-center items-center">
							{/* <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-10 h-10" viewBox="0 0 24 24">
                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
              </svg>
            </div> */}
							<div className="w-32 h-32 inline-flex items-center justify-center bg-pink-50 rounded-full mb-5">
								<img className="w-16 h-12" src={itinerary} alt="itinerary icon" />
							</div>
							<div className="flex-grow">
								<h2 className="text-lg font-medium mb-3">Thoughtful Itineraries</h2>
								<p className="leading-relaxed font-light text-xs">
									Itineraries that are designed to reveal region’s diverse culture,
									heritage and countryside.
								</p>
								{/* <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a> */}
							</div>
						</div>
						<div className="p-4 md:w-1/2 lg:w-1/4 flex flex-col text-center items-center">
							<div className="w-32 h-32 inline-flex items-center justify-center bg-blue-50 rounded-full mb-5">
								<img className="w-16 h-12" src={bicycle} alt="bicycle icon" />
							</div>
							<div className="flex-grow">
								<h2 className="text-lg font-medium mb-3">Quality Equipment</h2>
								<p className="leading-relaxed font-light text-xs">
									When you have a road and the thrill of a journey ahead of you, it’s
									imperative to have a good equipment to enjoy it.
								</p>
							</div>
						</div>
						<div className="p-4 md:w-1/2 lg:w-1/4 flex flex-col text-center items-center">
							<div className="w-32 h-32 inline-flex items-center justify-center bg-yellow-50 rounded-full mb-5">
								<img className="w-16 h-12" src={guide} alt="guide icon" />
							</div>
							<div className="flex-grow">
								<h2 className="text-lg font-medium mb-3">Expert Guides</h2>
								<p className="leading-relaxed font-light text-xs">
									The folks who steer you towards a unique and enriching experience and
									add meaning to your vacation.
								</p>
							</div>
						</div>
						<div className="p-4 md:w-1/2 lg:w-1/4 flex flex-col text-center items-center">
							<div className="w-32 h-32 inline-flex items-center justify-center bg-green-50 rounded-full mb-5">
								<img className="w-16 h-12" src={minded} alt="like minded icon" />
							</div>
							<div className="flex-grow">
								<h2 className="text-lg font-medium mb-3">Amazing Travel Pals</h2>
								<p className="leading-relaxed font-light text-xs">
									Connect with outdoor adventurers, who enjoy the camaraderie of
									exploring the world with like-minded people.
								</p>
							</div>
						</div>
					</div>
					{/* <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button> */}
				</div>
			</section>

			<section className="bg-gradient-to-r from-white to-secondary -mx-6 md:-mx-16">
				<div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
					{/* <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
						<Link
							to="/"
							className="flex font-medium items-center md:justify-start justify-center "
						>
							<span className="ml-6 text-xl font-medium hover:text-primary">
								Roots & Routes
							</span>
						</Link>
						<p className="mt-2 ml-6 text-sm font-light">
							We're the bicycle touring specialists.
						</p>
					</div> */}
					<div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
						<div className="lg:w-1/4 md:w-1/2 w-full px-4">
							<h2 className="font-semibold tracking-wider text-sm mb-3">
								DESTINATIONS
							</h2>
							<nav className="list-none mb-10">
								{/* <li>
									<Link
										to="/destinations/bhutan"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Bhutan
									</Link>
								</li> */}
								{/* <li>
									<Link
										to="/destinations/cambodia"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Cambodia
									</Link>
								</li> */}
								<li className="">
									<Link
										to="/destinations/france"
										className=" hover:text-primary text-sm text-gray-600"
									>
										France
									</Link>
								</li>
								<li className="">
									<Link
										to="/destinations/india"
										className=" hover:text-primary text-sm text-gray-600"
									>
										India
									</Link>
								</li>
								<li className="">
									<Link
										to="/destinations/italy"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Italy
									</Link>
								</li>
								{/* <li>
									<Link
										to="/destinations/laos"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Laos
									</Link>
								</li> */}
								<li>
									<Link
										to="/destinations/nepal"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Nepal
									</Link>
								</li>
								{/* <li>
									<Link
										to="/destinations/sri-lanka"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Sri Lanka
									</Link>
								</li> */}
								{/* <li>
									<Link
										to="/destinations/thailand"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Thailand
									</Link>
								</li> */}
								<li>
									<Link
										to="/destinations/vietnam"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Vietnam
									</Link>
								</li>
							</nav>
						</div>
						<div className="lg:w-1/4 md:w-1/2 w-full px-4">
							<h2 className="font-semibold  tracking-wider text-sm mb-3">
								ACTIVITIES
							</h2>
							<nav className="list-none mb-10">
								<li>
									<Link
										to="/activities/hiking"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Hiking
									</Link>
								</li>
								{/* <li>
									<Link
										to="/activities/cycling"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Cycling
									</Link>
								</li>
								<li>
									<Link
										to="/activities/multi-activity"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Multi-activity
									</Link>
								</li> */}
							</nav>
						</div>

						{/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
							<h2 className="font-semibold  tracking-wider text-sm mb-3">
								MORE SERVICES
							</h2>
							<nav className="list-none mb-10">
								<li>
									<Link
										to="/bicycle-service-whitefield-bangalore"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Bicycle Service
									</Link>
								</li>
								<li>
									<Link
										to="/basic-bicycle-maintenance-course"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Basic Bicycle Maintenance Course
									</Link>
								</li>
								<li>
									<Link
										to="/bicycle-rental-bangalore"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Bicycle Rentals
									</Link>
								</li>
							</nav>
						</div> */}

						{/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
							<h2 className="font-semibold  tracking-wider text-sm mb-3">
								SPECIALITY TRIPS
							</h2>
							<nav className="list-none mb-10">
								<li>
									<Link
										to="/specialities/family"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Family Adventures
									</Link>
								</li>
								<li>
									<Link
										to="/specialities/weekend-retreats"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Weekend Retreats
									</Link>
								</li>
								<li>
									<Link
										to="/specialities/day"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Day Trips
									</Link>
								</li>
								<li>
									<Link
										to="/specialities/e-bikes-available"
										className=" hover:text-primary text-sm text-gray-600"
									>
										E-bikes Available
									</Link>
								</li>
								<li>
									<Link
										to="/specialities/challenging-rides"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Challenging Rides
									</Link>
								</li>
								<li>
									<Link
										to="/specialities/new-trips"
										className=" hover:text-primary text-sm text-gray-600"
									>
										New Trips
									</Link>
								</li>
								<li>
									<Link
										to="/tours/sunday-social-rides"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Sunday Social Rides
									</Link>
								</li>
							</nav>
						</div> */}

						<div className="lg:w-1/4 md:w-1/2 w-full px-4">
							<h2 className="font-semibold  tracking-wider text-sm mb-3">
								OUR COMPANY
							</h2>
							<nav className="list-none mb-10">
								<li>
									<Link
										to="/about-us/our-company"
										className=" hover:text-primary text-sm text-gray-600"
									>
										About Us
									</Link>
								</li>
								{/* <li>
									<Link
										to="/bicycle-service-whitefield-bangalore"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Bicycle Service
									</Link>
								</li> */}
								{/* <li>
									<Link
										to="/in-the-news"
										className=" hover:text-primary text-sm text-gray-600"
									>
										In The News
									</Link>
								</li> */}
								{/* <li>
									<a
										href="http://eepurl.com/hnf28r"
										target="_blank"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Newsletter Signup
									</a>
								</li> */}
								{/* <li>
									<Link
										to="/membership"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Club Membership
									</Link>
								</li> */}
								{/* <li>
									<Link to="/blog" className=" hover:text-primary text-sm text-gray-600">
										Blog
									</Link>
								</li> */}
								{/* <li>
									<Link
										to="/about-us/work-with-us"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Work With Us
									</Link>
								</li> */}
								<li>
									<Link
										to="/terms-conditions"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Terms &amp; Conditions
									</Link>
								</li>
								<li>
									<Link
										to="/privacy-policy"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Privacy Policy
									</Link>
								</li>
								{/* <li>
									<Link
										to="/reviews"
										className=" hover:text-primary text-sm text-gray-600"
									>
										Reviews
									</Link>
								</li>
								<li>
									<Link to="/blog" className=" hover:text-primary text-sm text-gray-600">
										Blog
									</Link>
								</li> */}
							</nav>
						</div>
						<div className="lg:w-1/4 md:w-1/2 w-full px-4">
							<h2 className="font-semibold  tracking-wider text-sm mb-3">
								CONTACT US
							</h2>
							<nav className="list-none mb-10">
								{/* <li className="my-6 hidden lg:block">
									<Link
										to="/contact-us"
										className=" bg-primary py-2 px-2 sm:px-4 rounded-full text-white"
									>
										Send a Message
									</Link>
								</li> */}

								<li className="mt-6 mb-7">
									{/* <p className=" text-sm text-gray-600 uppercase mb-4 mt-10">
										WhatsApp :
									</p> */}
									<p className="">
										<a
											href="https://wa.me/919810645938"
											target="_blank"
											rel="noopener  noreferrer nofollow"
											className="bg-whatsapp font-medium py-2 px-5 sm:px-6 border border-gray-800 rounded-full text-gray-800"
										>
											WhatsApp Us
										</a>
									</p>
								</li>

								{/* <li className="mb-4">
									<p className=" text-sm text-gray-600">Call Us:</p>
									<a href="tel:+91-782-948-6953">+91 782 948 6953</a>
									<p className=" text-xs text-gray-600">(Mon-Fri: 9:30am - 5:30pm)</p>
								</li> */}
								{/* <li>
									<a
										className="hover:text-primary text-sm text-gray-600"
										href="https://g.page/artofbicycletrips?share"
										target="_blank"
									>
										Visit Us
									</a>
								</li> */}
								{/* <li>
									<p className=" text-sm text-gray-600">Email:</p>
									<a
										
										href="mailto:contact@artofbicycletrips.com"
									>
										contact@artofbicycletrips.com
									</a>
								</li> */}
								{/* <li className="my-10">
									<Location />
								</li> */}
							</nav>
						</div>
					</div>
				</div>

				<div>
					{/* <div className="flex justify-center space-x-2 ">
						<a href="https://www.instagram.com/artofbicycleclub/" target="_blank">
							<img className="" src={instagram} alt="instagram icon" />
						</a>
						<a
							href="https://www.youtube.com/channel/UC10rhc0jnPaJZRd4BwJpT1g"
							target="_blank"
						>
							<img className="" src={youtube} alt="youtube icon" />
						</a>
					</div> */}
					<div className="py-3 flex flex-col md:flex-row justify-center items-center space-x-1 mb-4 ">
						<p className="text-sm text-gray-500">© 2024 Roots & Routes </p>
					</div>

					<div className="text-center pb-2">
						<a href="#top" className="text-sm hover:text-primary">
							Go to Top <ChevronUpIcon className="h-4 w-4 text-sm inline-flex ml-2" />
						</a>
					</div>
				</div>
			</section>
		</footer>
	);
};

export default Footer;
