import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SearchResults from "~/components/search-results";
import Footer from "~/components/footer";
import Header from "~/components/header";
import { Link } from "gatsby";

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteNameQuery {
			strapiGlobal {
				siteName
			}
		}
	`);

	const [openModal, setOpenModal] = useState(false);

	return (
		<div className=" bg-white  relative px-6 md:px-16">
			<Header
				setOpenModal={setOpenModal}
				siteName={data.strapiGlobal.siteName || `Strapi`}
			/>

			<div className="flex flex-col max-w-screen-xl m-auto min-h-screen">
				<main className="flex-1">{children}</main>
				<Footer />
			</div>

			{/* <div className="sticky bottom-0 -mx-6 md:-mx-16">
				<Link to="/contact-us">
					<button class="w-full h-12 px-6 lg:hidden uppercase text-base font-medium text-white bg-primary">
						Contact us
					</button>
				</Link>
			</div> */}

			{openModal && (
				<div className="h-screen max-w-screen-lg m-auto fixed bottom-0 top-0 right-0 left-0 px-6 pb-10 pt-20 md:p-10 md:pt-40">
					<SearchResults setOpenModal={setOpenModal} openModal={openModal} />
				</div>
			)}
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
