import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Navbar from "~/components/navbar";
//import Banners from "~/components/banners";

const Header = ({ setOpenModal }) => {
	return (
		<header>
			<a name="top"></a>
			{/* <Banners /> */}
			<Navbar />
		</header>
	);
};

Header.propTypes = {
	siteName: PropTypes.string,
};

Header.defaultProps = {
	siteName: ``,
};

export default Header;
